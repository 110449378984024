import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper kfmb7ja80f7-editor_css' },
  page: { className: 'home-page kfnydj65ty-editor_css' },
  logo: {
    className: 'header3-logo',
    children: 'https://imgur.com/xTHMkh0.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>Home</p>
                </span>
              ),
              name: 'text',
            },
          ],
          target: '_blank',
        },
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: 'https://jessie557472.typeform.com/to/CGDFyn62',
          children: [
            {
              children: (
                <span>
                  <p>Start Saving Hours a Week</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item~kfnbikpwt8p',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>Sign In</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner40DataSource = {
  wrapper: { className: 'home-page-wrapper banner4 kfd38gt0e2-editor_css' },
  page: { className: 'home-page banner4-page kffzxxknr9-editor_css' },
  childWrapper: {
    className: 'banner4-title-wrapper kfdfm8w2yj6-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    Less Searching, More <i>Researching</i>
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'banner4-title kfd397190tf-editor_css',
      },
      {
        name: 'content',
        className: 'banner4-content kfd39nbhnu-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <span>
                                                                          <span>
                                                                            <span
                                                                            >
                                                                              <span
                                                                              >
                                                                                <span
                                                                                >
                                                                                  <span
                                                                                  >
                                                                                    <span
                                                                                    >
                                                                                      <span
                                                                                      >
                                                                                        <span
                                                                                        >
                                                                                          <span
                                                                                          >
                                                                                            <p
                                                                                            >
                                                                                              Attaché
                                                                                              automatically
                                                                                              curates,
                                                                                              organizes,
                                                                                              and
                                                                                              delivers
                                                                                              all
                                                                                              the
                                                                                              primary
                                                                                              information
                                                                                              you
                                                                                              care
                                                                                              about
                                                                                              on
                                                                                              the
                                                                                              daily.&nbsp;
                                                                                            </p>
                                                                                            <p
                                                                                            >
                                                                                              Stop
                                                                                              wasting
                                                                                              time
                                                                                              with&nbsp;<span
                                                                                              >
                                                                                                busywork,
                                                                                                and
                                                                                                let
                                                                                                our
                                                                                                ever-improving
                                                                                                AI
                                                                                                do
                                                                                                the
                                                                                                heavy
                                                                                                lifting
                                                                                                so
                                                                                                you
                                                                                                can
                                                                                                spend
                                                                                                time
                                                                                                on
                                                                                                what{' '}
                                                                                              </span>
                                                                                              <i
                                                                                              >
                                                                                                really{' '}
                                                                                              </i>
                                                                                              <span
                                                                                              >
                                                                                                matters.
                                                                                              </span>
                                                                                            </p>
                                                                                            <p
                                                                                            >
                                                                                              <br />
                                                                                            </p>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        children: {
          href: 'https://jessie557472.typeform.com/to/CGDFyn62',
          type: 'primary',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Try it First</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
          className: 'kfdfma6kpwg-editor_css',
        },
        className: 'kffx0f34ukh-editor_css',
      },
    ],
  },
  image: {
    className: 'banner4-image kfedsyn9l9-editor_css',
    children: 'https://imgur.com/dXKWtjb.png',
  },
};
export const Feature80DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature8-wrapper kffx0l32r36-editor_css',
  },
  page: { className: 'home-page feature8 kfm2rcb0k7f-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1 kffwk912kb8-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>How's Attaché Work?</p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      <i>Not just another static reference manager</i>
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: 'https://jessie557472.typeform.com/to/CGDFyn62',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>
                                  <br />
                                </p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
          type: 'primary',
          className: 'kfg858dt0lj-editor_css',
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row kfg275bygqr-editor_css',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child~kfwb0yefuik',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title kfg0l69tv8u-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <span>
                                                                          <span>
                                                                            <span
                                                                            >
                                                                              <span
                                                                              >
                                                                                <span
                                                                                >
                                                                                  <span
                                                                                  >
                                                                                    <span
                                                                                    >
                                                                                      <span
                                                                                      >
                                                                                        <p
                                                                                        >
                                                                                          <b
                                                                                          >
                                                                                            Search
                                                                                            for
                                                                                            an
                                                                                            article
                                                                                            or
                                                                                            enter
                                                                                            your
                                                                                            fields
                                                                                            of
                                                                                            interest.&nbsp;
                                                                                          </b>
                                                                                        </p>
                                                                                        <p
                                                                                        >
                                                                                          <b
                                                                                          >
                                                                                            <br />
                                                                                          </b>
                                                                                        </p>
                                                                                        <p
                                                                                        >
                                                                                          <b
                                                                                          >
                                                                                            <br />
                                                                                          </b>
                                                                                        </p>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content kfg1c6m9b7-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <p>
                                                                      Or drag
                                                                      &amp; drop
                                                                      your
                                                                      existing
                                                                      articles
                                                                      or
                                                                      citations
                                                                      from
                                                                      Zotero,
                                                                      Mendeley,
                                                                      your
                                                                      bookmarks,
                                                                      or other
                                                                      tools!&nbsp;
                                                                    </p>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow kfg27sennpd-editor_css',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title kfnyg57hjg-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <p>
                                          <b>
                                            Get results delivered to your
                                            dashboard or in an interactive email
                                            on a daily, weekly, or monthly basis
                                          </b>
                                        </p>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content kffygv318a-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <p>
                                                    <span>
                                                      We'll use our models and
                                                      knowledge graphs to search
                                                      through thousands of
                                                      journals and databases for
                                                      relevant articles, briefs,
                                                      patents, conference
                                                      proceedings, and
                                                      more.&nbsp;
                                                    </span>
                                                    <br />
                                                  </p>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title kfg0l69tv8u-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <span>
                                                                          <p>
                                                                            <b>
                                                                              Read
                                                                              and
                                                                              rate
                                                                              them
                                                                              at
                                                                              your
                                                                              leisure.&nbsp;Get
                                                                              anything
                                                                              from
                                                                              your
                                                                              subscribed
                                                                              journals
                                                                              with
                                                                              one
                                                                              click.
                                                                            </b>
                                                                          </p>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content kfg1c6m9b7-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <p>
                                                                    <span>
                                                                      Use any
                                                                      tool to
                                                                      read your
                                                                      articles,
                                                                      but the
                                                                      more you
                                                                      use your
                                                                      dashboard
                                                                      and reader
                                                                      the better
                                                                      our tools
                                                                      are at
                                                                      organizing
                                                                      your
                                                                      references,
                                                                      giving
                                                                      recommendations,
                                                                      and
                                                                      keeping
                                                                      you
                                                                      productive.
                                                                    </span>
                                                                    <br />
                                                                  </p>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Content10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper kg6ebp4eewm-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://imgur.com/R79pqVV.png',
    className: 'kfg90roa2dr-editor_css',
  },
  textWrapper: {
    className: 'content1-text kg6eo0x4qj-editor_css',
    md: 14,
    xs: 24,
  },
  title: {
    className: 'content1-title kfg32z8bvys-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>An Ever-Vigilant Assistant</p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content kfg28ytk74-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <p>
                                                                          <i>
                                                                            Designed
                                                                            to
                                                                            keep
                                                                            you
                                                                            abreast
                                                                            of
                                                                            everything
                                                                            that
                                                                            matters
                                                                          </i>
                                                                        </p>
                                                                        <p>
                                                                          <i>
                                                                            <br />
                                                                          </i>
                                                                        </p>
                                                                        <p>
                                                                          Based
                                                                          on
                                                                          what
                                                                          you
                                                                          read
                                                                          and
                                                                          like
                                                                          we'll
                                                                          continue
                                                                          to
                                                                          deliver
                                                                          related
                                                                          articles,
                                                                          as
                                                                          well
                                                                          as
                                                                          keep
                                                                          an eye
                                                                          on
                                                                          updates,
                                                                          retractions,
                                                                          emerging
                                                                          subtopics,
                                                                          papers
                                                                          and
                                                                          conferences
                                                                          featuring
                                                                          co-authors,
                                                                          and
                                                                          best
                                                                          of all
                                                                          related
                                                                          grants
                                                                          and
                                                                          other
                                                                          funding
                                                                          opportunities.&nbsp;
                                                                        </p>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper kfm5m1xpke-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3, replay: false },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://imgur.com/xTWL6Ha.png',
    className: 'kfge3zxd27f-editor_css',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title kfg32unxiq-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <p>
                                            Like Spotify 'Discover Weekly' for
                                            Research
                                          </p>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content kfg3353k0fu-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <p>
                                                    <i>
                                                      Made for those Eureka
                                                      moments&nbsp;
                                                    </i>
                                                  </p>
                                                  <p>
                                                    <i>
                                                      <br />
                                                    </i>
                                                  </p>
                                                  <p>
                                                    Unlike existing, passive
                                                    tools like Zotero, Mendeley,
                                                    or Endnote our models are
                                                    constantly evolving, taking
                                                    in highlights, shared
                                                    articles, emerging and
                                                    adjacent topics and
                                                    more--making sure to surface
                                                    cutting edge insights in
                                                    that you might otherwise
                                                    miss.&nbsp;
                                                    Attaché&nbsp;really is your{' '}
                                                    <i>personal </i>research
                                                    engine.
                                                  </p>
                                                  <p>
                                                    <br />
                                                  </p>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper kfw7mxpapoj-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://imgur.com/bgAgqQb.png',
    className: 'kfgf3pgqzx-editor_css',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title kfgg7oljkt9-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>Your Team's Mission Control</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content kfw7mpvhvzq-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <p>
                                                                          <i>
                                                                            Works
                                                                            better
                                                                            when
                                                                            you're
                                                                            together.
                                                                          </i>
                                                                        </p>
                                                                        <p>
                                                                          <i>
                                                                            <br />
                                                                          </i>
                                                                        </p>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <p>
                                                                          <span>
                                                                            Perfect
                                                                            for
                                                                            remote
                                                                            teams,
                                                                            labs,
                                                                            and
                                                                            large
                                                                            organizations
                                                                            trying
                                                                            to
                                                                            get
                                                                            everyone
                                                                            on
                                                                            the
                                                                            same
                                                                            page.&nbsp;
                                                                          </span>
                                                                          <span>
                                                                            No
                                                                            matter
                                                                            your
                                                                            size
                                                                            or
                                                                            field
                                                                            we
                                                                            make
                                                                            sure
                                                                            everyone
                                                                            in
                                                                            your
                                                                            group
                                                                            is
                                                                            well-informed
                                                                            with
                                                                            easy{' '}
                                                                          </span>sharing<span
                                                                          >
                                                                            ,
                                                                            group
                                                                            reading
                                                                            lists,
                                                                            tools
                                                                            to
                                                                            securely
                                                                            manage
                                                                            &amp;
                                                                            track
                                                                            internal
                                                                            work,
                                                                            and
                                                                            integrations
                                                                            with
                                                                            all
                                                                            your
                                                                            established
                                                                            productivity
                                                                            software.
                                                                            A
                                                                          </span>
                                                                          <span>
                                                                            ll
                                                                            accessible
                                                                            in
                                                                            one
                                                                            place.
                                                                          </span>
                                                                        </p>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Content30DataSource = {
  wrapper: {
    className: 'home-page-wrapper content3-wrapper kfm2qwggog-editor_css',
  },
  page: { className: 'home-page content3 kfm2lqqod9s-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>Productivity Tools for Researchers by Researchers</p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1 kfg2qu8o2o7-editor_css',
      },
      {
        name: 'content',
        className: 'title-content kffshsk4gsn-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <p>
                                                    <i>
                                                      We're dedicated to
                                                      filtering through the
                                                      noise so you can actually
                                                      generate insights,
                                                    </i>
                                                  </p>
                                                  <p>
                                                    <i>&nbsp;</i>
                                                    <i>
                                                      so Attaché&nbsp;also
                                                      features tools designed to
                                                      cut through the busywork.
                                                    </i>
                                                  </p>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kfw7n756xn7-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Timeline</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kfwaivrjg67-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>
                                    Never forget what you read, where you left
                                    off, or how you got there again. We generate
                                    custom recaps of your sessions so you know
                                    exactly which of your references are
                                    important and why.&nbsp;
                                  </p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon kfg06tkocji-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kfw7naaklck-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <p>Semantic Search</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kfwaj5rtwng-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              Using semantic search powered by OpenAI's GPT-3
                              find exactly what you're looking for—even if you
                              don't remember <i>exactly </i>what it is. Use
                              natural language or descriptive search for both
                              your files and millions of documents we have
                              access to.<p>
                                <br />
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kfw7ndkwzrg-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Rich Annotations</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kfwb701jgjt-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Why settle for simple highlights? Link voice memos, video
                      clips, code snippets, and more! Best of all, easily export
                      them and their context to notetaking and productivity
                      tools like Roam.
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kfw7nh4pi7-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Single Login</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kfwb7h96mx-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      End the context-switching with One Click login for all
                      your organization's journals and databases. Easily
                      collaborate and form reading groups.
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kfw7nk55147-editor_css',
            children: (
              <span>
                <span>
                  <p>Automatic Tagging</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kfwb8d0uvze-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>
                                  We assign tags automatically and link which
                                  sources are related to one another. And to cut
                                  back on time spent cleaning up old info, we
                                  periodically remind you which are ripe for
                                  pruning.
                                </p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title kfw7nn2f9d-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Integrates With Your Existing Writing Tools</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content kfwb8v2v988-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>
                              When it's time to write reports, compile
                              literature reviews, or finish off that thesis
                              easily import citations and get contextual
                              information from your saved documents.
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Pricing01DataSource = {
  wrapper: {
    className: 'home-page-wrapper pricing0-wrapper kfw7h75tx4j-editor_css',
  },
  OverPack: {
    playScale: 0.3,
    className: 'home-page pricing0 kfm5fmeo98k-editor_css',
  },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children: 'https://imgur.com/kuqP78r.png',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    Stop wasting time searching and really start{' '}
                    <i>researching</i>.
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-title kfm60ixjby-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <p>
                                                            <b>
                                                              With existing
                                                              tools falling
                                                              short, and work
                                                              continuing to
                                                              encroach on free
                                                              time, we're driven
                                                              to give
                                                              researchers tools
                                                              to make
                                                              fact-finding a
                                                              little more
                                                              bearable. We're
                                                              excited to launch
                                                              in Winter 2020, sign
                                                              up below to be the
                                                              first to get an
                                                              edge!
                                                            </b>
                                                            <br />
                                                          </p>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-content kfm60wigqet-editor_css',
      },
      {
        name: 'button',
        children: {
          href: 'https://jessie557472.typeform.com/to/CGDFyn62',
          type: 'primary',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <p>Join the waitlist</p>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
          className: 'kfm5tx7e4k8-editor_css',
        },
        className: 'kfm5wbvdj9k-editor_css',
      },
    ],
  },
};
